import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Contactbar from "../../components/contact-info/contact-info";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const RetirementAccounts = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const seoData = {
    title: "Personal Retirement Savings Accounts",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Personal Retirement Savings Accounts"
      },
      {
        name: "description",
        content:
          "Saving for retirement is one of the most important things you can do to secure your future. Learn more and get started today with WaFd Bank."
      },
      {
        property: "og:title",
        content: "Personal Retirement Savings Accounts"
      },
      {
        property: "og:description",
        content:
          "Saving for retirement is one of the most important things you can do to secure your future. Learn more and get started today with WaFd Bank."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/retirement-ira-accounts"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-ira-03-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "ira-hero",
    ...getHeroImgVariables(imgData),
    altText: "Little girl being taught how to ride a bicycle by her grandfather at the park",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Start Saving Now and Your Future Self Will Thank You"
          }
        },
        {
          id: 2,
          button: {
            id: "ira-hero-cta",
            text: "Find a Local Branch",
            url: "/locations",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Retirement"
    }
  ];

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Retirement</h1>
        <h3>
          At some point, we all stop working and retire. While this may feel far away, it's not too early to start
          planning your financial future. Unfortunately, most Americans aren't as prepared as they should be for
          retirement. If you're thinking about starting to save, then an Individual Retirement Account, or IRA, may be
          right for you.*
        </h3>
        <h2 className="text-success">Nearing Retirement</h2>
        <div className="row">
          <div className="col-md-6">
            <p>
              You've worked hard to earn what you have, and we want to help you make the most of it. If your next
              chapter in life is enjoying retirement, an IRA, which is subject to special tax advantages, helps you
              build savings more effectively. You can move your funds into CDs, money market, or savings, all of which
              can be set up as IRA accounts in either Roth or Traditional plans.
            </p>
            <StaticImage
              src="../../images/thumbnail-retirement-nearing-090523.jpg"
              alt="Mature couple laughing and gardening."
              quality="100"
              placeholder="blurred"
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <h5>What is an IRA?</h5>
            <p>
              An IRA is known as an Individual Retirement Account. This account falls under the category of retirement,
              so it has specific federal laws that apply, but otherwise functions similar to your personal accounts.
              Depending on the IRA plan type that you choose, Traditional or Roth, you can earn money in your WaFd IRA
              from compound interest each year. Regardless of the plan type you choose, money in your retirement account
              is not taxed while the money stays in an IRA plan. WaFd Bank offers certificates of deposit, savings, and
              money market account IRAs.
            </p>
            <h5>I'd like to open an IRA. What are my options?</h5>
            <p>
              WaFd Bank offers two types of IRAs, Roth and Traditional. The main difference is the way they are taxed.
              When you open a Roth IRA, taxes are paid before putting money into the account, so you don't have to pay
              taxes when you withdraw your money in retirement. On the other hand, when you put money into a Traditional
              IRA you do not pay taxes. Instead, when you reach retirement and begin to withdraw your money, you'll pay
              taxes at the time of your withdrawal.
            </p>
            <Link to="/locations" className="btn btn-primary" id="locations-link-what-is-ira-section">
              Find a Local Branch
            </Link>
          </div>
        </div>
      </section>
      <section className="container py-0">
        <table className="table table-bordered d-sm-table d-none">
          <thead className="bg-info text-white">
            <tr id="ira-table-title">
              <th className="w-25">Exploring Your IRA Option</th>
              <th>Roth IRA</th>
              <th>Traditional IRA</th>
            </tr>
          </thead>
          <tbody>
            <tr id="ira-table-can-i-contribute-row">
              <td>
                <strong>Can I contribute?</strong>
              </td>
              <td>
                You are eligible to contribute if you earn compensation (or file a joint tax return with a spouse who
                earns compensation) and your modified adjusted gross income (MAGI) is less than or within the defined
                limits. More information about MAGI limits is available on the{" "}
                <a
                  id="irs-individual-retirement-arrangements-iras-external-link"
                  rel="noopener noreferrer"
                  href="https://www.irs.gov/retirement-plans/individual-retirement-arrangements-iras"
                  target="_blank"
                >
                  IRS website
                </a>
                .
              </td>
              <td>
                You are eligible to contribute at any age if you earn compensation (or file a joint return with a spouse
                who earns compensation).
              </td>
            </tr>
            <tr id="ira-table-tax-deduction-row">
              <td>
                <strong>Can I take an income tax deduction for my contribution?</strong>
              </td>
              <td>No. Roth IRA contributions are not tax-deductible.</td>
              <td>
                It depends on your marital and tax-filing status, your MAGI, and whether you or your spouse actively
                participates in an employer-sponsored retirement plan. If neither you nor your spouse is an active
                participant, then you are eligible to deduct your full contribution.
              </td>
            </tr>
            <tr id="ira-table-benefits-row">
              <td>
                <strong>What are the benefits?</strong>
              </td>
              <td>
                <ul>
                  <li>You may qualify for a saver's tax credit of up to $1,000 when you make a contribution.</li>
                  <li>
                    Because all Roth IRA contributions must be included in your taxable income, and therefore are not
                    tax-deductible, you can withdraw your contributions at any time, tax- and penalty-free.
                  </li>
                  <li>
                    Any earnings generated within the IRA are tax-deferred (you do not pay tax on the earnings until you
                    withdraw them.)
                  </li>
                  <li>
                    If you satisfy the qualified distribution requirements, you can withdraw the earnings tax-free,
                    which is the ultimate advantage of having a Roth IRA.
                  </li>
                  <li>You are never required to take money out of your Roth IRA, no matter what your age.</li>
                  <li>Restrictions may apply.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>You may qualify for a saver's tax credit of up to $1,000 when you make a contribution.</li>
                  <li>
                    Any earnings generated within the IRA are tax-deferred (you do not pay tax on the earnings until you
                    withdraw them).
                  </li>
                  <li>
                    If your Traditional IRA contributions are tax-deductible and therefore tax-deferred, you do not pay
                    taxes on them until you withdraw the money.
                  </li>
                  <li>
                    Any after-tax amounts (nondeductible contributions) within your IRA can be withdrawn tax- and
                    penalty-free.
                  </li>
                </ul>
              </td>
            </tr>
            <tr id="ira-table-required-withdraw-row">
              <td>
                <strong>Will I ever be required to withdraw the money?</strong>
              </td>
              <td>
                No. Roth IRA owners are never required to take distributions. After your death, however, your
                beneficiaries may be subject to required distributions.
              </td>
              <td>
                Yes. Traditional IRA owners are required to take annual minimum distributions beginning for the year
                they turn age 73 years. Your beneficiaries may also will be subject to required distributions.
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered d-sm-none d-table">
          <thead className="bg-info text-white">
            <tr id="roth-ira-table-title">
              <th></th>
              <th>Roth IRA</th>
            </tr>
          </thead>
          <tbody>
            <tr id="roth-ira-table-can-i-contribute-row">
              <td>
                <strong>Can I contribute?</strong>
              </td>
              <td>
                You are eligible to contribute if you earn compensation (or file a joint tax return with a spouse who
                earns compensation) and your modified adjusted gross income (MAGI) is less than or within the defined
                limits. More information about MAGI limits is available on the
                <a
                  id="irs-individual-retirement-arrangements-iras-external-link"
                  rel="noopener noreferrer"
                  href="https://www.irs.gov/retirement-plans/individual-retirement-arrangements-iras"
                  target="_blank"
                >
                  IRS website
                </a>
                .
              </td>
            </tr>
            <tr id="roth-ira-table-tax-deduction-row">
              <td>
                <strong>Can I take an income tax deduction for my contribution?</strong>
              </td>
              <td> No. Roth IRA contributions are not tax-deductible.</td>
            </tr>
            <tr id="roth-ira-table-benefits-row">
              <td>
                <strong>What are the benefits?</strong>
              </td>
              <td>
                <ul>
                  <li>You may qualify for a saver's tax credit of up to $1,000 when you make a contribution.</li>
                  <li>
                    Because all Roth IRA contributions must be included in your taxable income, and therefore are not
                    tax-deductible, you can withdraw your contributions at any time, tax- and penalty-free.
                  </li>
                  <li>
                    Any earnings generated within the IRA are tax-deferred (you do not pay tax on the earnings until you
                    withdraw them.)
                  </li>
                  <li>
                    If you satisfy the qualified distribution requirements, you can withdraw the earnings tax-free,
                    which is the ultimate advantage of having a Roth IRA.
                  </li>
                  <li>You are never required to take money out of your Roth IRA, no matter what your age.</li>
                  <li>Restrictions may apply.</li>
                </ul>
              </td>
            </tr>
            <tr id="roth-ira-table-required-withdraw-row">
              <td>
                <strong>Will I ever be required to withdraw the money?</strong>
              </td>
              <td>
                No. Roth IRA owners are never required to take distributions. After your death, however, your
                beneficiaries may be subject to required distributions.
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered d-sm-none d-table">
          <thead className="bg-info text-white">
            <tr id="traditional-ira-table-title">
              <th></th>
              <th>Traditional IRA</th>
            </tr>
          </thead>
          <tbody>
            <tr id="traditional-ira-table-can-i-contribute-row">
              <td>
                <strong>Can I contribute?</strong>
              </td>
              <td>
                You are eligible to contribute if you are under age 70 years and 6 months and earn compensation (or file
                a joint return with a spouse who earns compensation).
              </td>
            </tr>
            <tr id="traditional-ira-table-tax-deduction-row">
              <td>
                <strong>Can I take an income tax deduction for my contribution?</strong>
              </td>
              <td>
                It depends on your marital and tax-filing status, your MAGI, and whether you or your spouse actively
                participates in an employer-sponsored retirement plan. If neither you nor your spouse is an active
                participant, then you are eligible to deduct your full contribution.
              </td>
            </tr>
            <tr id="traditional-ira-table-benefits-row">
              <td>
                <strong>What are the benefits?</strong>
              </td>
              <td>
                <ul>
                  <li>You may qualify for a saver's tax credit of up to $1,000 when you make a contribution.</li>
                  <li>
                    Any earnings generated within the IRA are tax-deferred (you do not pay tax on the earnings until you
                    withdraw them).
                  </li>
                  <li>
                    If your Traditional IRA contributions are tax-deductible and therefore tax-deferred, you do not pay
                    taxes on them until you withdraw the money.
                  </li>
                  <li>
                    Any after-tax amounts (nondeductible contributions) within your IRA can be withdrawn tax- and
                    penalty-free.
                  </li>
                </ul>
              </td>
            </tr>
            <tr id="traditional-ira-table-required-withdraw-row">
              <td>
                <strong>Will I ever be required to withdraw the money?</strong>
              </td>
              <td>
                Yes. Traditional IRA owners are required to take annual minimum distributions beginning for the year
                they turn age 70 years and 6 months. Your beneficiaries also will be subject to required distributions.
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row my-4">
          <div className="col-md-6">
            <h2 className="text-success">Living in Retirement</h2>
            <h4>
              Your hard work has allowed you to enjoy the retirement chapter of your life. Now more than ever, it's
              important to make the most of your money so you can continue to enjoy what you've earned. We've got some
              ideas to help.* Plus, at WaFd Bank, we offer no-fee IRA rollovers and transfers.
            </h4>
            <h5>Rolling over your IRA for a better retirement</h5>
            <p>
              Rolling over retirement plan assets to another retirement plan or an IRA will not only preserve your tax
              benefits, but may possibly provide you with additional benefits.
            </p>
            <Link to="/locations" className="btn btn-primary" id="locations-link-living-in-retirement-section">
              Find a Local Branch
            </Link>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <StaticImage
              src="../../images/thumbnail-retirement-living-in-090523.jpg"
              alt="Mother and adult daughter hugging and smiling. "
              quality="100"
              placeholder="blurred"
            />
          </div>
        </div>
        <table className="table table-bordered">
          <tbody>
            <tr id="retirement-table-roll-it-out-row">
              <td className="w-25">
                <strong>When You Can Roll It Out </strong>
              </td>
              <td className="w-75">
                <p>
                  Employee-sponsored retirement plans have &ldquo;triggering events&rdquo; that dictate when you may
                  withdraw the money from your account, such as attainment of retirement age, termination of employment,
                  disability, or death.
                </p>
                <p>
                  Next, you must ensure that the amount you are taking is eligible to be rolled over. Most distributions
                  from retirement plans are eligible to be rolled over to IRAs or other eligible retirement plans, but
                  some are not.
                </p>
                <p>Talk to your employer about your options on either of the above.</p>
              </td>
            </tr>
            <tr id="retirement-table-roll-to-traditional-row">
              <td>
                <strong>What can I roll over to a Traditional IRA?</strong>
              </td>
              <td>
                <ul>
                  <li>Qualified Retirement Plans such as 401(k) Plans</li>
                  <li>Federal Thrift Savings Plans</li>
                  <li>403(b) Plans</li>
                  <li>Governmental 457(b) plans</li>
                  <li>Simple IRA Plans</li>
                </ul>
              </td>
            </tr>
            <tr id="retirement-table-roll-to-roth-row">
              <td>
                <strong>What can I roll over to a Roth IRA?</strong>
              </td>
              <td>
                <ul>
                  <li>Qualified Retirement Plans such as 401(k) Plans</li>
                  <li>Federal Thrift Savings Plans</li>
                  <li>403(b) Plans</li>
                  <li>Governmental 457(b) plans</li>
                  <li>
                    Designated Roth Plan Accounts<sup>**</sup>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-muted text-sm">
          <span id="ira-disclaimer-1">
            *Please consult with a tax professional to determine which options meet your financial goals.
          </span>
          <br />
          <span id="ira-disclaimer-2">
            **Pretax assets rolled over from a retirement plan to a Roth IRA must be included in taxable income in the
            year of distribution.
          </span>
        </p>
      </section>
      <Contactbar />
    </SecondaryLanding>
  );
};

export default RetirementAccounts;
